<template>
    <v-navigation-drawer
        v-model="drawer"
        :width="$vuetify.display.mobile ? 300 : 360"
        location="right"
        absolute
        temporary
        @update:modelValue="updateModel"
    >
        <!-- <v-tabs
            v-model="tab"
            grow
            bg-color="transparent"
        >
            <v-tab value="notice">Notice</v-tab>
            <v-tab value="news">News</v-tab>
        </v-tabs> -->
        <div class="pa-3" v-if="tab == 'notice'">
            <div class="notifications-container">
                <div class="success">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            
                            <svg class="succes-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                            </svg>
                        </div>
                        <div class="success-prompt-wrap">
                            <p class="success-prompt-heading">Order completed
                            </p><div class="success-prompt-prompt">
                            <div>
                                Buy <b>0.2 ETH</b> at price <b>3898.12</b><br />Total <b>779.624 USDT</b>
                                <p class="time-text">2024-03-16 09:02:02</p>
                            </div>
                            </div>
                            <div class="success-button-container">
                                <button type="button" class="success-button-main">View</button>
                                <button type="button" class="success-button-secondary">Dismiss</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="notifications-container mt-3">
                <div class="success">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            
                            <svg class="succes-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                            </svg>
                        </div>
                        <div class="success-prompt-wrap">
                            <p class="success-prompt-heading">Order completed
                            </p><div class="error-prompt-prompt">
                            <div>
                                Sell <b>0.2 ETH</b> at price <b>4012.5</b><br />Total <b>802.5 USDT</b>
                                <p class="time-text">2024-03-15 08:33:08</p>
                            </div>
                            </div>
                            <div class="success-button-container">
                                <button type="button" class="success-button-main">View</button>
                                <button type="button" class="success-button-secondary">Dismiss</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="news-box" v-else>
          <div class="action-box pa-3 d-flex align-center">
            <v-btn class="btn-item mr-2" color="#fff" @click="prevClick">
              <v-icon>mdi-chevron-left</v-icon>
              Prev
            </v-btn>
            <v-btn class="btn-item" color="#fff" @click="nextClick">
              Next
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <div class="news-content-box pa-3">
            <div class="d-flex align-center justify-center mt-6" v-if="loading">
              <v-btn :loading="loading" variant="text" ></v-btn>
            </div>
            <template v-else>
              <div class="card mb-3" v-for="item in news" :key="item.id">
                <h3 class="card__title" @click="urlClick(item)">{{item.title}}</h3>
                <p class="card__content" v-html="item.content"></p>
                <div class="card__date">
                  {{new Date(item.create_time * 1000).toLocaleString()}}&nbsp;&nbsp;<span class="font-weight-bold">By BlockBeats</span>
                </div>
                <div class="card__arrow" @click="urlClick(item)">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="15" width="15">
                    <path fill="#fff" d="M13.4697 17.9697C13.1768 18.2626 13.1768 18.7374 13.4697 19.0303C13.7626 19.3232 14.2374 19.3232 14.5303 19.0303L20.3232 13.2374C21.0066 12.554 21.0066 11.446 20.3232 10.7626L14.5303 4.96967C14.2374 4.67678 13.7626 4.67678 13.4697 4.96967C13.1768 5.26256 13.1768 5.73744 13.4697 6.03033L18.6893 11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H18.6893L13.4697 17.9697Z"></path>
                  </svg>
                </div>
              </div>
            </template>
          </div>
        </div>
    </v-navigation-drawer>
</template>

<script setup>
import { ref,defineProps,watch,computed ,defineEmits} from 'vue';
import { PublicStore } from '@/stores/public';
import { reactive } from 'vue';
const publicStore = PublicStore()
// $vuetify.display.mobile
const emits = defineEmits(["sidebar:update"]);
let news = []
const drawer = ref(false)
const props = defineProps({
  showDrawer: {
    type: Boolean,
    default: () => false,
  },
});
const tab = ref("news")
const loading = ref(false)
let newsBody = reactive({
  page:1,
  size:10,
  type:'news',
  lang:'en'
})
const _showDrawer = computed(() => {
  return props.showDrawer;
});
function updateModel() {
  emits("sidebarinfo:update");
}
function prevClick() {
  if(newsBody.page == 1) {
    return
  }
  newsBody.page -= 1;
  getNews()
}
function nextClick() {
  newsBody.page += 1;
  getNews()
}
async function getNews() {
  loading.value = true
  let res = await publicStore.theblockbeatsNews(newsBody)
  loading.value = false
  if(res.status == 0) {
    news = res.data.data
    if(newsBody.page == 1) {
      localStorage.setItem('last-news-id',news[0].id)
    }
  }
}
function urlClick(item) {
  window.open(item.url)
}
watch(_showDrawer, (v) => {
    drawer.value = v;
    if(v) {
      getNews()
    }else {
      newsBody.page = 1
    }
});
</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer__content {
  overflow-y: hidden !important;
}
.v-navigation-drawer {
  position: fixed !important;
}
.time-text {
    color: #707070;
    font-size: 14px;
    margin-top:8px;
}
.notifications-container {
//   width: 320px;
  height: auto;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flex {
  display: flex;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.success {
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: rgb(240 253 244);
}
.error {
  padding: 1rem;
  border-radius: 0.375rem;
  background-color: rgb(253, 245, 240);
}

.succes-svg {
  color: rgb(74 222 128);
  width: 1.25rem;
  height: 1.25rem;
}

.success-prompt-wrap {
  margin-left: 0.75rem;
}

.success-prompt-heading {
  font-weight: bold;
  color: rgb(22 101 52);
}
.error-prompt-heading {
  font-weight: bold;
  color: rgb(101, 22, 22);
}
.success-prompt-prompt {
  margin-top: 0.5rem;
  color: rgb(21 128 61);
}
.error-prompt-prompt {
  margin-top: 0.5rem;
  color: rgb(198 66 66);
}

.success-button-container {
  display: flex;
  margin-top: 4px;
  margin-bottom: -0.375rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.success-button-main {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #ECFDF5;
  color: rgb(22 101 52);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  border-radius: 0.375rem;
  border: none
}

.success-button-main:hover {
  background-color: #D1FAE5;
}

.success-button-secondary {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.75rem;
  background-color: #ECFDF5;
  color: #065F46;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.375rem;
  border: none;
}

// -----------------
/* this card is inspired form this - https://georgefrancis.dev/ */

.card {
  --border-radius: 0.75rem;
  --primary-color: #7257fa;
  --secondary-color: #3c3852;
  padding: 1rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  background-color: #000;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
  position: relative;
}

.card > * + * {
  margin-top: 1.1em;
}

.card .card__content {
  color: #808080;
  font-size: 0.86rem;
}

.card .card__title {
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.card .card__date {
  color: #d6d6d6;
  font-size: 0.8rem;
}

.card .card__arrow {
  position: absolute;
  background: var(--primary-color);
  padding: 0.4rem;
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  bottom: 0;
  right: 0;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card svg {
  transition: 0.2s;
}

/* hover */
.card:hover .card__title {
  color: var(--primary-color);
  text-decoration: underline;
}

.card:hover .card__arrow {
  background: #57d1a2;
}

.card:hover .card__arrow svg {
  transform: translateX(3px);
}
.action-box {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  padding-bottom: 6px;
  border-bottom: 1px solid #505050;
  background-color: #222;
  z-index: 100;
  .btn-item {
    flex:1;
  }
}
.news-box {
  height: 100%;
}
.news-content-box {
  height: calc(100% - 60px);
  margin-top:60px;
  overflow-y: auto;
}
</style>