export default {
    "en": {
        text1: 'Create bot',
        text2: 'Bot information',
        text3: 'Create a new bot by “Select your exchange” and “Select your pair”.',
        text4: 'Input your stake.',
        text5: 'Input your grid settings: High, Low and Levels.',
        text6: 'Input your Advance setting and then tap “CONFIRM”. Make sure all the settings are correct.',
        text7: 'Start and confirm to create this bot.',
        text8: 'Fill in your Algo724 username & password and click “SIGN IN”.',
        text9: 'Portfolio analysis',
        text10: 'Press “ADD NEW EXCHANGE” to link your Binance account.',
        text11: 'Fill in the API key and Secret key and tap “CONNECT”.',
        text12: 'Fill in your Algo724 username & password and click “SIGN IN”.',
        text13: "Operating guide",
        text14: "Welcome to Algo724, you can start your value -added road from here.",
        text15: "The following is the basic operation guide",
        text16: "Connect to an exchange ",
        text17: " , Algo724 guarantees the security of users' funds, all operations are through the exchange,You can click ",
        text18: "here",
        text19: " to add a exchange",
        text20: "Create strategy robot ",
        text21: ", According to your trading habits, you can create a variety of different types of trading robots and try to click on the ",
        text22: " button on the top",
        text23: "-There are several main functional areas such as product quotation, K -line, performance panel, robot list, etc.",
        text24: "Assets and performance ",
        text25: ", You can see your asset trend and the profitability of each robot in the form of a chart. ",
        text26: "click to view",
        text27: "Signal system ",
        text28: ", Algo724 provides various indicators to help you analyze and develop strategies, and provide VIP strategy services.",
        text29: "Basic settings ",
        text30: ", Here you can connect your Telegram, Metamask, Google account, and set your security options and view bills, etc.",
        text31: "Click to go",
        text32: "Now you can start your journey of capital growth!",
        text33: "Don't show again",
        text34: 'I see'
    },
    "zh": {
        text1: '創建機器人',
        text2: '機器人信息',
        text3: '通過“選擇你的交易所”和“選擇你的配對”來創建一個新的機器人。',
        text4: '輸入您的賭注。',
        text5: '輸入您的網格設置：高、低和級別。',
        text6: '輸入您的高級設置，然後點擊“確認”。 確保所有設置都正確。',
        text7: '開始並確認創建此機器人。',
        text8: '填寫您的Algo724用戶名和密碼，點擊“登入”。',
        text9: '投資組合分析',
        text10: '按“添加新交易所”鏈接您的幣安賬戶。',
        text11: '填寫API key和Secret key，點擊“CONNECT”。',
        text12: '填寫您的Algo724用戶名和密碼，點擊“登入”。',
        text13: "操作指南",
        text14: "歡迎來到Algo724，您可以從這裡開始您的資產增值之路。",
        text15: "以下為基本操作指南",
        text16: "連接到交易所",
        text17: ",Algo724保證用戶資金安全，所有操作均通過交易所進行，您可以點擊",
        text18: "這裡",
        text19: "添加交易所",
        text20: "創建策略機器人",
        text21: ", 根據您的交易習慣，您可以創建多種不同類型的交易機器人並嘗試點擊",
        text22: "頂部的按鈕",
        text23: "-產品行情、K線、表現面板、機器人列表等幾個主要功能區。",
        text24: "資產和績效",
        text25: ", 您可以通過圖表的形式看到您的資產走勢和每個機器人的盈利情況。",
        text26: "點擊查看",
        text27: "信號系統",
        text28: ", Algo724提供各種指標幫助您分析制定策略，並提供VIP策略服務。",
        text29: "基本設置",
        text30: ", 在這裡您可以連接您的 Telegram、Metamask、Google 帳戶，並設置您的安全選項和查看賬單等。",
        text31: "點擊前往",
        text32: "現在你可以開始你的資本增值之旅了！",
        text33: "不再顯示",
        text34: "我明白了"
    }
}