import { en } from 'vuetify/locale'
import login from './login'
import drawers from './drawers';
import trade from './trade';
import portfolio from './portfolio';
import exchange from './exchange'
import setting from './setting';
import subscription from './subscription';
import guide from './guide';
import mobile from './mobile'
export default {
    label: 'English',
    //page login
    login: 'Login',
    register: 'Register',
    username: 'Username',
    password: 'Password',
    login_account: 'Login accoount',
    // menu
    menu: {
        home: 'Home',
        dashboard: 'Dashboard',
    },
    //
    ...en,
    loginLang: {
        ...login.en
    },
    drawers: {
        ...drawers.en
    },
    trade: {
        ...trade.en
    },
    portfolio: {
        ...portfolio.en
    },
    exchange: {
        ...exchange.en
    },
    setting: {
        ...setting.en
    },
    subscription: {
        ...subscription.en
    },
    guide: {
        ...guide.en
    },
    mobile: {
        ...mobile.en
    }
}