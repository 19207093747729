<!-- Toast.vue -->
<template>
  <transition name="fade">
    <div v-if="visible" :class="`toast ${typeClass}`">
      <i
        class="v-icon notranslate mdi mdi-alert-circle"
        style="margin-right: 5px"
      ></i
      >{{ message }}
    </div>
  </transition>
</template>
  
  <script>
export default {
  data() {
    return {
      visible: false,
      message: "",
      typeClass: "",
    };
  },
  methods: {
    show(message) {
      this.message = message;
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, 2000);
    },
    success(message) {
      this.typeClass = "message--success";
      this.show(message);
    },
    error(message) {
      this.typeClass = "message--error";
      this.show(message);
    },
  },
};
</script>
  
  <style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@media (max-width: 768px) {
  .toast {
    width: 80%;
  }
}
.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: rgba(0, 0, 0, 0.8); */
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 10000;
  display: flex;
  align-items: center;
}
.message--success {
  background-color: #f0f9eb;
  border-color: #e1f3d8;
  color: #67c23a;
}
.message--error {
  background-color: #f56c6c;
  border-color: #f56c6c;
  color: #ffffff;
}
</style>
  