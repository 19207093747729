export default {
    "en":{
        text1:'Total balance',
        text2:'Portfolio Allocation',
        text3:'Portfolio History',
        text4:'Bot performance',
        text5:'Running',
        text6:'Closed',
        text7:'All',
        text8:'Asset',
        text9:'Currency',
        text10:'Free',
        text11:'Lock',
        text12:'Asset trend',
        text13:'HODL',
        text14:'Please add your exchange account',
        text15:'Go to add exchange account'
    },
    "zh":{
        text1:'總資產',
        text2:'資產分佈',
        text3:'盈虧歷史',
        text4:'Bot 績效',
        text5:'進行中',
        text6:'已關閉',
        text7:'所有',
        text8:'資產',
        text9:'幣種',
        text10:'可用',
        text11:'凍結',
        text12:'資產走勢',
        text13:'HODL',
        text14:'請添加你的交易所賬戶',
        text15:'立即添加'
    }
}