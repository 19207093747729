export default {
    "en": {
        text1: 'Connected exchanges',
        text2: 'Add new exchange',
        text3: 'Connect new exchange',
        text4: 'Account name',
        text5: 'Name',
        text6: 'Cannot be empty',
        text7: 'Exchange',
        text8: 'Sandbox',
        text9: 'Connect',
        text10: 'Balance',
        text11: 'Invalid Account name',
        text12: 'Are you sure you want to delete this account?',
        text13: 'Validating API'
    },
    "zh": {
        text1: '連接交易所',
        text2: '添加新交易所賬號',
        text3: '連接新交易所',
        text4: '帳號',
        text5: '別名',
        text6: '不可為空',
        text7: '交易所',
        text8: 'Sandbox',
        text9: '連接',
        text10: '資產',
        text11: '名稱格式有誤',
        text12: '確定要刪除此賬戶嗎？',
        text13: '正在驗證API'
    }
}