let config = {
    BASEURL: window.location.origin,
    WS_URL: `wss://${window.location.host}/ws/`
}
if (process.env.NODE_ENV == 'development') {
    config = {
        // BASEURL:'http://cube.uat.algo724.com/',
        // WS_URL: 'wss://cube.uat.algo724.com/ws/'
        BASEURL: 'https://app.algo724.com/',
        // WS_URL: 'wss://test.algo724.com/ws/'
    }
}

const version = "/api/v1"
const NETWORK_INFURA_API_KEY = "03f196e11a744e288d4ccca6e22006f3"
const ALCHEMY_API_KEY = 'ivWm3G-Hxe7LYXixLvQZ2jWwkEM754Wb'
const GOOGLE_CLIENT_ID = '496870199022-gqlonrdrm2kalscrf4j7hvtd8n5q0521.apps.googleusercontent.com'
const WALLET_CONNECT_ID = "6ebc8da6df970367a7d1e8426a3cedf4";
export default config;

export { version, NETWORK_INFURA_API_KEY, ALCHEMY_API_KEY, GOOGLE_CLIENT_ID, WALLET_CONNECT_ID }