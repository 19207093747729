<template>
    <div class="footer-page pa-6">
      <div class="property-right d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <span style="font-size: 20px" class="ml-1">©</span>
          <span class="">{{new Date().getFullYear()}} Algo724 All Rights Reserved</span>
        </div>
        <div class="link-box d-flex align-center pa-6 pt-2 pb-2 rounded-pill">
          <div
            class="d-flex align-center link-item"
            @click="openWindow('https://www.algo724.com/blog-pages/blog')"
          >
            <!-- <img src="../../public/img/icon/blog.png" width="24" alt="" /> -->
            <a class="ml-1">BLOG</a>
          </div>
          <div
            class="d-flex align-center link-item ml-8"
            @click="openWindow('https://t.me/algo724')"
          >
            <!-- <img src="../../public/img/icon/telegram.png" width="22" alt="" /> -->
            <a class="ml-1">TELEGRAM</a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  function openWindow(url) {
    window.open(url);
  }
  </script>
  
  
  <style lang="scss" scoped>
  .footer-page {
    border-top: 1px solid #131313;
    background: #000;
    // position: fixed;
    // width: calc(100% - 56px);
    bottom: 0;
    // height: 50px;
    .property-right {
      font-size: 14px;
      color: #6c6c6c;
    }
    .link-box {
    //   background-color: #212121;
      .link-item {
        cursor: pointer;
      }
      a {
        color: #d3d3d3;
        font-size: 14px;
        font-weight: 400;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  </style>