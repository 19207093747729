export default {
    "en": {
        text1: 'Account details',
        text2: 'Security',
        text3: 'Billing',
        text4: 'Username',
        text5: 'Link',
        text6: 'Linked',
        text7: 'Membership',
        text8: 'Upgrade',
        text9: 'User Guide',
        text10: 'Once',
        text11: 'Always',
        text12: 'Never',
        text13: 'Current password',
        text14: 'New password',
        text15: 'Confirm new password',
        text16: 'Required',
        text17: 'Save new password',
        text18: 'Date',
        text19: 'Method',
        text20: 'Plan',
        text21: 'Total amount',
        text22: 'Order status',
        text23: 'Invoice',
        text24: 'Open',
        text25: 'Unsubscribe',
        text26: 'Inconsistent passwords',
        text27: 'This account has been bound, do you want to bind again?',
        text28: 'Are you sure you want to unsubscribe?',
        text29: 'Password reset complete!',
        text30: 'Link your Telegram account?',
        text31: 'Unlink your Telegram account?',
        text32: 'Unsubscribe Success!',
        text33: 'Link Success!',
        text34: 'Unlink Success!',
        text35: 'Link your wallet account?',
        text36: 'Unlink your wallet account?',
        text37: 'Link your Google account?',
        text38: 'Unlink your Google account?',
        text39: 'Bind or unbind your telegram account',
        text40: 'Bind or unbind your wallet account',
        text41: 'Bind or unbind your Google account',
        text42: 'upgrade your plan',
        text43: 'Whether to display user guidance'
    },
    "zh": {
        text1: '賬戶詳情',
        text2: '安全',
        text3: '賬單',
        text4: '用戶名',
        text5: '綁定',
        text6: '解除綁定',
        text7: '會員資格',
        text8: '升級',
        text9: '用戶指南',
        text10: '顯示一次',
        text11: '總是顯示',
        text12: '從不顯示',
        text13: '當前密碼',
        text14: '新密碼',
        text15: '確認新密碼',
        text16: '不可為空',
        text17: '保存新密碼',
        text18: '日期',
        text19: '方法',
        text20: '計劃',
        text21: '總金額',
        text22: '訂單狀態',
        text23: '發票',
        text24: '打開',
        text25: '取消訂閱',
        text26: '密碼不一致',
        text27: '此帳戶已綁定，是否要再次綁定?',
        text28: '確定要取消訂閱嗎？ ',
        text29: '密碼重置成功',
        text30: '綁定您的telegram賬戶？ ',
        text31: '解綁您的telegram賬戶？ ',
        text32: '訂閱已取消!',
        text33: '綁定成功!',
        text34: '解除綁定成功!',
        text35: '綁定您的錢包賬戶？',
        text36: '解綁您的錢包賬戶？',
        text37: '綁定您的Google賬戶？',
        text38: '解綁您的Google賬戶？',
        text39: '綁定或者解綁您的telegram帳戶',
        text40: '綁定或者解綁您的Metamask帳戶',
        text41: '綁定或者解綁您的Google帳戶',
        text42: '陞級您的套餐',
        text43: '是否顯示用戶引導',
    }
}