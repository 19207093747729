// toast.js
import { createApp } from 'vue';
import ToastComponent from './Index.vue';

const Message = {
    install(app) {
        const container = document.createElement('div');
        document.body.appendChild(container);

        const instance = createApp(ToastComponent);
        const toast = instance.mount(container);

        app.config.globalProperties.$message = toast;
    },
};

export default Message;
