export default {
    "en":{
        text1:'Monthly billing',
        text2:'Yearly billing',
        text3:'Premium',
        text4:'per user / month',
        text5:'For personal',
        text6:'Smart grid bot',
        text7:'booster bot',
        text8:'Tiger bot',
        text9:'Portfolio analysis',
        text10:'Ultimate',
        text11:'For professional traders',
        text12:'All functions of premium membership',
        text13:'Signal alert / subscription',
        text14:'Telegram notification',
        text15:'Take profit / Stop loss',
        text16:'Enterprise',
        text17:'Custom',
        text18:'For enterprise customised solution',
        text19:'Subscribe',
        text20:'Contact Sales',
        text21:'Expires in ',
        text22:' days',
        text23:'Current membership period: Till ',
        text24:'per user / year',
        text25:'m',
        text26:'y'
    },
    "zh":{
        text1:'按月計費',
        text2:'按年計費',
        text3:'高級',
        text4:'/月',
        text5:'個人',
        text6:'所有可用的基本機器人功能',
        text7:'投資組合分析',
        text8:'電報通知',
        text9:'獲利/止損',
        text10:'終極',
        text11:'專業交易者',
        text12:'信號提醒/訂閱',
        text13:'回測優化',
        text14:'為機器人預設信號觸發',
        text15:'專業的投資組合分析器',
        text16:'企業',
        text17:'自定義',
        text18:'企業定制解決方案',
        text19:'訂閱',
        text20:'聯繫銷售',
        text21:'將於',
        text22:'天后到期',
        text23:'當前會員有效期至：',
        text24:'/年',
        text25:'月',
        text26:'年'
    }
}