import { createI18n } from 'vue-i18n'
import zhHans from '@/locale/zhHans';   // 导入简体中文语言
import en from '@/locale/en';            // 导入English语言
const i18n = createI18n({
    legacy: false,
    locale: 'en-us', // 默认显示语言
    messages: {
        'zh-cn': zhHans,
        'en-us': en
    }
})
export default i18n