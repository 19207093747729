export default {
    "en": {
        // text1:'Press "Launch Bot"',
        history: {
            text1: 'No data',
            text2: 'Bot',
            text3: 'Closed Bot',
            text4: 'Balance',
            text5: 'Order history',
            text6: 'Type',
            text7: 'Account',
            text8: 'Pair',
            text9: 'Investment',
            text10: 'Range',
            text11: 'Level',
            text12: 'PNL',
            text13: 'Initial Price',
            text14: 'Bot ID',
            text15: 'History',
            text16: 'Asset',
            text17: 'Free',
            text18: 'Locked',
            text19: 'Symbol',
            text20: 'Price',
            text21: 'Amount',
            text22: 'Time',
            text23: 'EXPORT',
            text24: 'ALL',
            text25: 'Profit & Loss',
            text26: 'Close',
            text27: 'Confirm',
            text28: 'Cancel',
            text29: 'Profit',
            text30: 'Analysis',
            text31: 'Est. APY',
            text32: 'Accruing profit',
            text33: 'Range',
            text34: 'Level',
            text35: 'Initial Price',
            text36: 'Starting Date',
            text37: '7 Days',
            text38: '30 Days',
            text39: 'All',
            text40: 'Are you sure to change the state of the bot?',
            text41: 'Are you sure you want to close this bot?',
            text42: "Action",
            text43: 'State',
            text44: "Init Range"
        },
        createBot: {
            text1: 'Bot',
            text2: 'Launch Bot',
            text3: 'Create ',
            text4: 'General settings',
            text5: 'Select your exchange',
            text6: 'Select your pair',
            text7: 'More pair',
            text8: 'CONTINUE',
            text9: 'Back prev',
            text10: 'Investment',
            text11: 'Input your stake',
            text12: 'Input your Maximum Investment',
            text13: 'Grid setting',
            text14: 'Input your High',
            text15: 'Input your Low',
            text16: 'Input your Levels',
            text17: 'Advance setting',
            text18: 'Trail Up',
            text19: 'Trail Down',
            text20: 'Take profit',
            text21: 'Stop Loss',
            text22: 'Comfirm',
            text23: 'STARTBOT',
            text24: 'Input your pair',
            text25: 'Cancel',
            text26: 'Required',
            text27: 'Format error',
            text28: 'The transaction pair was not found!',
            text29: 'Press "Launch Bot"',
            text30: 'Modify',
            text31: "Modify Bot",
            text32: "Input your TP. price",
            text33: "Input your SL. price",
            text34: "Initial Price",
            text35: "Base amount",
            text36: "Quote amount",
            text37: "Are you sure to create this robot?"
        }
    },
    "zh": {
        history: {
            text1: '沒有數據',
            text2: '機器人',
            text3: '已關閉機器人',
            text4: '資產',
            text5: '訂單歷史',
            text6: '類型',
            text7: '賬戶',
            text8: '交易對',
            text9: '金額',
            text10: '範圍',
            text11: '網格基數',
            text12: '總體收益',
            text13: '創建價格',
            text14: 'Bot ID',
            text15: '訂單歷史',
            text16: '資產',
            text17: '可用',
            text18: '凍結',
            text19: '幣種',
            text20: '價格',
            text21: '數量',
            text22: '時間',
            text23: '導出數據',
            text24: '所有',
            text25: '盈虧',
            text26: '關閉',
            text27: '確認',
            text28: '取消',
            text29: '盈利',
            text30: '分析',
            text31: '預估年收益',
            text32: '累計收益',
            text33: '範圍',
            text34: '網格基數',
            text35: '創建價格',
            text36: '開始日期',
            text37: '7天',
            text38: '30天',
            text39: '所有',
            text40: '您確定要更改機器人運行狀態嗎？',
            text41: '您確定要關閉此機器人？',
            text42: "操作",
            text43: '狀態',
            text44: "初始範圍"
        },
        createBot: {
            text1: 'Bot',
            text2: '啟動機器人',
            text3: '創建 ',
            text4: '常規設置',
            text5: '選擇你的賬戶',
            text6: '選擇你的交易對',
            text7: '更多交易對',
            text8: '繼續',
            text9: '返回上一步',
            text10: '投資',
            text11: '輸入你的投資金額',
            text12: '輸入你最大投資金額',
            text13: '網格設置',
            text14: '輸入你的最高價',
            text15: '輸入你的最低價',
            text16: '輸入你的網格基數',
            text17: '高級設置',
            text18: '向上追踪',
            text19: '向下追踪',
            text20: '止盈',
            text21: '止損',
            text22: '確認',
            text23: '立即創建',
            text24: '輸入交易對',
            text25: '取消',
            text26: '不可為空',
            text27: '格式錯誤',
            text28: '未找到該交易對',
            text29: '按下 "Launch Bot"',
            text30: '修改',
            text31: "修改 Bot",
            text32: "輸入你的止盈價格",
            text33: "輸入你的止損價格",
            text34: "初始價格",
            text35: "交易幣金額",
            text36: "報價幣金額",
            text37: "您確定創建此機器人嗎?"
        }
    }
}