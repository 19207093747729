import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'

import 'vuetify/styles'
import vuetify from "./plugins/vuetify"
import i18n from './plugins/i18n'
import Message from "./plugins/toast/index.js";
import echarts from './plugins/echarts'
import * as commonFunctions from "@/utils/common"
import './registerServiceWorker'


export const app = createApp(App)
app.use(router).use(vuetify).use(createPinia()).use(i18n).use(Message);
app.provide('i18n', i18n); // 提供 i18n 变量
app.mixin({ methods: { ...commonFunctions } })
app.config.globalProperties.echarts = echarts;
app.mount('#app')