<template>
  <!-- temporary：默认不显示，点击展开，适用移动端 -->
  <!-- permanent，保持可见性，否则窄屏不显示 -->
  <!-- rail：窄的，否则默认展开 -->
  <!-- expand-on-hover： hover 展开 -->
  <v-navigation-drawer rail expand-on-hover permanent color="#171717" @update:rail="updateRail">
    <div class="card-box">
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <span class="sidebar-title">Algo724</span>
          </v-list-item-title>
          <template v-slot:prepend>
            <v-img :src="logo" :width="28" class="mr-7"></v-img>
          </template>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <p class="sub-title" v-if="!isRail">GENERAL</p>
        <v-list-item
          height="50"
          title="Home"
          value="Home"
          @click="$router.push('/home')"
        >
          <template v-slot:prepend>
            <v-img :src="home" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <v-list-item
          id="trade_sidebar"
          height="50"
          title="Trade"
          value="Trade"
          @click="$router.push('/trade')"
        >
          <template v-slot:prepend>
            <v-img :src="trade" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <v-list-item
          id="portfolio_sidebar"
          height="50"
          title="Portfolio"
          value="Portfolio"
          @click="$router.push('/portfolio')"
        >
          <template v-slot:prepend>
            <v-img :src="portfolio" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <p class="sub-title" v-if="!isRail">EXTRA</p>
        <v-list-item
          id="aave_sidebar"
          height="50"
          title="AAVE"
          value="AAVE"
          @click="$router.push('/aave')"
        >
          <template v-slot:prepend>
            <v-icon color="#fff">mdi-alpha-a-circle</v-icon>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <v-list-item
          id="uniswap_sidebar"
          height="50"
          title="Uniswap"
          value="uniswap"
          @click="$router.push('/uniswap')"
        >
          <template v-slot:prepend>
            <v-img :src="uniswap" :width="24" class="mr-8"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <p class="sub-title" v-if="!isRail">ACCOUNT</p>
        <v-list-item
          id="account_sidebar"
          height="50"
          title="Account"
          value="Account"
          @click="$router.push('/account')"
        >
          <template v-slot:prepend>
            <v-img :src="bank" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <v-list-item
          id="setting_sidebar"
          height="50"
          title="Setting"
          value="Setting"
          @click="$router.push('/setting')"
        >
          <template v-slot:prepend>
            <v-img :src="setting" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import logo from "../../../public/images/logo.png";
// import home from "../../../public/images/sidebar/compass.png";
import home from "../../../public/images/sidebar/icons/home.png";
import trade from "../../../public/images/sidebar/icons/trade.png";
import portfolio from "../../../public/images/sidebar/icons/portfolio.png";
import bank from "../../../public/images/sidebar/icons/account.png";
import setting from "../../../public/images/sidebar/icons/setting.png";
// import aave from "../../../public/images/sidebar/aave.png";
import uniswap from "../../../public/images/sidebar/uniswap.png";
import { ref } from "vue";
const isRail = ref(true);
function updateRail(v) {
  isRail.value = v;
}
</script>

<style lang="scss" scoped>
@import "../../assets/fonts/poppins/font.css";
::v-deep .v-list-item--one-line {
  min-height: 47px !important;
}
::v-deep .v-list-item {
  &:hover {
    .v-img,i {
      animation: swing ease-in-out 0.5s 1 alternate;
    }
    .v-list-item-title {
      color: #fff;
    }
  }
}
::v-deep .v-list-item-title {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  
  line-height: 20px;
  color: #b8b8b8;
}
.sidebar-title {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 100;
}
.sub-title {
  font-family: "Poppins", sans-serif !important;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #888888;
  margin-bottom:6px;
  margin-top:10px;
  padding-left: 4px;
}
@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(16deg);
  }

  40% {
    transform: rotate(-16deg);
  }

  60% {
    transform: rotate(8deg);
  }

  80% {
    transform: rotate(-8deg);
  }
}
</style>
