
import axios from 'axios'
import config from '@/config'
import { app } from '@/main.js'
import qs from 'qs'
import { PublicStore } from "@/stores/public";
import { setCookie, delCookie, getCookie } from '@/utils/cookie'

// 创建axios实例
const service = axios.create({
    baseURL: config.BASEURL, // api的base_url
    timeout: 40000, // 请求超时时间,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    },
})
// request拦截器
service.interceptors.request.use(configs => {
    if (window.BASEURL) {
        configs.baseURL = window.BASEURL
    }
    let data;
    if (configs.method === 'post' || configs.method === 'delete') {
        data = configs.data && JSON.parse(JSON.stringify(configs.data));
    } else {
        data = configs.params && JSON.parse(JSON.stringify(configs.params));
    }
    if ((configs.method === 'post' || configs.method === 'delete') && configs.baseURL == config.BASEURL) {
        if (configs.url.indexOf('/api/v2') > -1) {
            configs.data = JSON.stringify(data)
        } else {
            configs.data = qs.stringify(data)
        }
    } else {
        configs.params = data
    }
    if (configs.url != '/api/v1/client/getAccessToken' && configs.baseURL.indexOf('news.algo724.com') == -1) {
        configs.headers = {
            ...configs.headers,
            "Authorization": "Bearer " + getCookie('access_token')
        }
    }
    return configs;
}, error => {
    Promise.reject(error)
})

var retry_times = {}
// respone拦截器
service.interceptors.response.use(
    response => {
        if (response.status == 200) {
            if (retry_times[response.config.url]) {
                retry_times[response.config.url] = 0
            }
            return response.data
        }
    },
    async error => {
        if (error.response.status == 401) {
            let expiredToken = [-10002, -10003, -11001, -1, -10004, -19002]
            if (expiredToken.indexOf(error.response.data.code) > -1 || expiredToken.indexOf(error.response.data.status) > -1) {
                const configData = error.config;
                if (configData.url == '/api/v1/client/getAccessToken') {
                    removeData()
                    return
                }
                let bodyData = qs.parse(configData.data)
                let access_token = await accessToken()
                if (!access_token) {
                    return Promise.reject(error)
                }

                return await service({ method: configData.method, url: configData.url, data: bodyData, params: configData.params, headers: { 'Authorization': `Bearer ${access_token}` } })
            }
            if (error.response.data.code == -10005) {
                //refresh_token 过期了
                removeData()
                return
            }
            if (error.response.data.code == -11039) {
                const publicStore = PublicStore();
                publicStore.setNeed2Fa(true)
                return
            }
        } else if (error.response.status == 400) {
            //tg绑过别的账号了
            if (error.response.data.code == -11008) {
                // store.commit('setTgReBind', true)
            }
        }

        if (error.config.url.indexOf("/api/v2") > -1 && error.config.method == 'get') {
            const headers = {
                'Authorization': `Bearer ${getCookie('access_token')}`
            }
            await sleep();

            if (retry_times[error.config.url]) {
                retry_times[error.config.url] += 1
            } else {
                retry_times[error.config.url] = 1
            }
            if (retry_times[error.config.url] >= 5) {
                return Promise.reject(error)
            }
            return await service({ method: error.config.method, url: error.config.url, data: error.config.data, params: error.config.params, headers })
        }
        app.config.globalProperties.$message.error(error.response.data.error)

        // return Promise.reject(error)
    }
)

async function sleep() {
    return new Promise(resolve => setTimeout(resolve, 500))
}

async function accessToken() {

    const refresh_token = localStorage.getItem('refresh_token')
    if (!refresh_token) {
        //refresh_token 不存在
        removeData()
        return
    }

    try {
        const response = await service.get('/api/v1/client/getAccessToken', {
            headers: {
                "Authorization": "Bearer " + refresh_token
            }
        });
        if (response && response.access_token) {
            setCookie("access_token", response.access_token)
            localStorage.setItem('access_token', response.access_token)
            return response.access_token
        }

    } catch (error) {
        removeData()
    }
}

function removeData() {
    delCookie('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('access_token')
    const publicStore = PublicStore();
    publicStore.setIsLogin(false)
    location.href = "/login"
}

export default service