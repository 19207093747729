// import zhHans from 'vuetify/es5/locale/zh-Hans';
import { zhHans } from 'vuetify/locale'
import login from './login'
import drawers from './drawers';
import trade from './trade';
import portfolio from './portfolio';
import exchange from './exchange';
import setting from './setting';
import subscription from './subscription';
import guide from './guide';
import mobile from './mobile'
export default {
    label: '繁體中文',
    // page login
    login: '登入',
    register: '註冊',
    username: '用戶名',
    password: '密碼',
    login_account: '登入帳戶',
    // menu
    menu: {
        home: '首頁',
        dashboard: '儀錶盤',
    },
    // 展开 简体中文语言包的所有项目
    ...zhHans,
    loginLang: {
        ...login.zh
    },
    drawers: {
        ...drawers.zh
    },
    trade: {
        ...trade.zh
    },
    portfolio: {
        ...portfolio.zh
    },
    exchange: {
        ...exchange.zh
    },
    setting: {
        ...setting.zh
    },
    subscription: {
        ...subscription.zh
    },
    guide: {
        ...guide.zh
    },
    mobile: {
        ...mobile.zh
    }

}