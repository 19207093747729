export default {
    "en": {
        text1: 'Home',
        text2: 'Trade',
        text3: 'Portfolio',
        text4: '✨Subscription',
        text5: 'Signal',
        text6: 'Exchange',
        text7: 'Setting',
        text8: 'Logout',
        text9: 'Market'
    },
    "zh": {
        text1: '首頁',
        text2: '交易',
        text3: '資產',
        text4: '✨訂閱',
        text5: '信號',
        text6: '交易所API',
        text7: '設置',
        text8: '登出',
        text9: '市場'
    }
}