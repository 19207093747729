export default {
    "en": {
        text1: "Choose an account",
        text2: "Use another account",
        text3: "Remove another account",
        text4: 'Username',
        text5: 'Required',
        text6: 'One-click login',
        text7: 'Telegram Login',
        text8: 'Sign In',
        text9: 'Login',
        text10: 'Or connect with',
        text11: "Don't have an account?",
        text12: 'Apply for beta invitation',
        text13: 'Invalid username.',
        text14: 'Input your username',
        text15: 'Input your password',
        text16: 'Password',
        text17: 'Input your email',
        text18: 'Successfully registered, now jump to login'
    },
    "zh": {
        text1: '選擇一個賬戶',
        text2: "使用其他賬戶",
        text3: "刪除賬戶",
        text4: '用戶名',
        text5: '不可為空',
        text6: '一鍵登入',
        text7: 'Telegram 登入',
        text8: '登入',
        text9: '登入',
        text10: '其他方式',
        text11: "還沒有賬戶？",
        text12: '申請測試版邀請賬戶',
        text13: '用戶名格式錯誤',
        text14: '請輸入用戶名',
        text15: '請輸入密碼',
        text16: '密碼',
        text17: '請輸入邮箱',
        text18: '注册成功，現在跳轉去登入'
    }
}