export default {
    "en": {
        text1: "OVERVIEW",
        text2: "Logged In",
        text3: 'Explore',
        text4: 'Base currency & Quoted currency',
        text5: 'TRADE',
        text6: 'PORTFOLIO',
        text7: 'Bot Running',
        text8: 'Bot Closed',
        text9: 'Closing Date',
        text10: 'Paused',
        text11: 'Running',
        text12: 'Stopped',
        text13: 'Pause',
        text14: 'Resume'
    },
    "zh": {
        text1: '概覽',
        text2: '登入中',
        text3: '詳情',
        text4: '基礎貨幣和報價貨幣',
        text5: '交易',
        text6: '資產',
        text7: '運行中',
        text8: '已關閉',
        text9: '關閉日期',
        text10: '已暫停',
        text11: '運行中',
        text12: '已停止',
        text13: '暫停',
        text14: '運行'
    }
}