import { createPinia, defineStore } from 'pinia'
const pinia = createPinia()
import { version } from "@/config/index"
import service from '@/servers/fetch'
import { PublicStore } from "@/stores/public";
export const HomeStore = defineStore('Home', {
    state: () => {
        return {
            pairList: [],
            currentSymbol: "",
            botList: [],
            exhibitBot: {},
            botSnapshotData: {},
            userBalances: [],
            exchangeList: [],
            currentBotOrderHistory: [],
            currentAccount: "",
            botListCardHeight: 640,
            totalProfit:{
                totalProfit:'--',
                realisedProfit:'--',
                profitAPY:'--',
                unrealisedProfitAPY:'--',
                isHave:false,
            }
        }
    },
    getters: {
        formatBotList() {
            let data = this.botList;
            data = data.filter((v) => {
                let ex = this.formatExchangeList.filter(item => {
                    return item.id == v.exchange_id
                })
                return ex && ex.length > 0
            });
            return data
        },
        formatExchangeList() {
            if (this.exchangeList.length == 0) {
                return []
            }
            const publicStore = PublicStore();
            const isDemo = publicStore.isDemo;
            return this.exchangeList.filter(item => {
                return isDemo ? item.demo : !item.demo;
            })
        },
        rankBotList() {
            if(this.formatBotList.length == 0) {
                return []
            }
            let data = JSON.parse(JSON.stringify(this.formatBotList))
            data = data.sort((a, b) => {
                return b.profit_pct - a.profit_pct
            })
            return data
            // if (data.length >= 3) {
            //     let rank1 = data && data[0];
            //     let rank2 = data && data[1]
            //     let rank3 = data && data[2]
            //     let other = data.slice(3)
            //     return {
            //         rank1,
            //         rank2,
            //         rank3,
            //         other
            //     }
            // }
            // return {
            //     rank1: undefined,
            //     rank2: undefined,
            //     rank3: undefined,
            //     other: data
            // }

        },
        formatUserBalance() {
            if (this.formatExchangeList.length == 0 || this.userBalances.length == 0) {
                return []
            }
            const ids = this.formatExchangeList.map(item => {
                return item.id
            })
            return this.userBalances.filter(item => {
                return ids.indexOf(item.id) > -1
            })
        }
    },
    actions: {
        setTotalProfitData(v) {
            this.totalProfit = v
        },
        setCurrentBotOrderHistory(v) {
            this.currentBotOrderHistory = v
        },
        setBotListHeight(v) {
            this.botListCardHeight = v
        },
        setCurrentAccount(v) {
            this.currentAccount = v
        },
        setPairList(v) {
            this.pairList = v
        },
        setCurrentSymbol(v) {
            this.currentSymbol = v
        },
        setBotList(v) {
            this.botList = v
        },
        setExhibitBot(v) {
            this.exhibitBot = v
        },
        setBotSnapshotData(v) {
            this.botSnapshotData = v
        },
        setBalances(v) {
            this.userBalances = v
        },
        async getMarketPair(data, single = false) {
            let response = await service({
                url: `${version}/market/pair`,
                method: 'get',
                params: data
            })
            if (!single) {
                this.pairList = response.symbols
                this.setCurrentSymbol(response.symbols[0].symbol)
            }
            return response
        },
        async getBotList(data) {
            let response = await service({
                url: `${version}/bot/list`,
                method: 'get',
                params: data,
            })
            this.botList = response.bots;
            // let activeBot = this.botList.filter((item) => {
            //     return item.status == 0;
            // }).sort((a, b) => {
            //     return b.profit_pct - a.profit_pct;
            // });
            // if (activeBot.length == 0) {
            //     this.exhibitBot = {}
            // } else {
            //     this.exhibitBot = activeBot[0]
            // }
            return response;
        },
        //获取机器人绩效
        async getPortfolioSnapshotBot(data) {
            let response = await service({
                url: `${version}/portfolio/snapshot/bot`,
                method: 'get',
                params: data
            })
            this.botSnapshotData = response
            return response
        },
        async getPortfolioBalances(data) {
            let response = await service({
                url: `${version}/portfolio/balances`,
                method: 'get',
                params: data,
            })
            this.userBalances = response.exchanges
            return response
        },
        async getExchangeCheckList(data) {
            let response = await service({
                url: `${version}/exchange/checkExchange`,
                method: 'get',
                params: data
            })
            this.exchangeList = response.exchanges
            return response;
        },
        async createBot(data) {
            let response = await service({
                url: `${version}/bot/createBot`,
                method: 'post',
                data
            })
            return response;

        },
        async getOrderListBotOrder(data) {
            let response = await service({
                url: `${version}/order/listBotOrder`,
                method: 'get',
                params: data,
            })
            // this.currentBotOrderHistory = (response.bots[0] && response.bots[0].orders) || []
            return response;
        },
        async modifyBot(data) {
            let response = await service({
                url: `${version}/bot/modifyBot`,
                method: 'post',
                data,
            })
            return response;
        },
        async pauseBot(data) {
            let response = await service({
                url: `${version}/bot/pauseBot`,
                method: 'post',
                data
            })
            return response
        },
        async resumeBot(data) {
            let response = await service({
                url: `${version}/bot/resumeBot`,
                method: 'post',
                data
            })
            return response
        },
        async stopBotFetch(data) {
            let response = await service({
                url: `${version}/bot/stopBot`,
                method: 'post',
                data
            })
            return response
        },

    }
})

export default pinia;